export const SEX_ITEMS = [
  { key: "ユニセックス", value: "unisex" },
  { key: "メンズ", value: "mens" },
  { key: "レディース", value: "ladies" },
] as const

export const FABRIC_ITEMS = [
  { key: "メッシュ", value: "mesh" },
  { key: "ドライ", value: "dry" },
  { key: "ガーゼ", value: "gauze" },
  { key: "スウェット", value: "sweat" },
  { key: "裏起毛スウェット", value: "lining-sweat" },
  { key: "パイル", value: "pile" },
  { key: "プレミアムパイル", value: "premium-pile" },
  { key: "サテン", value: "satin" },
  { key: "ベロア", value: "velor" },
  { key: "ソフトニット", value: "soft-knit" },
] as const

export const SEASON_ITEMS = [
  { key: "春~夏", value: "ss" },
  { key: "秋~冬", value: "fw" },
] as const

export const WEAR_CATEGORY_ITEMS = [
  { key: "上下セット", value: "set" },
  { key: "トップス", value: "tops" },
  { key: "ボトムス", value: "bottoms" },
  { key: "アイテム", value: "item" },
] as const

export const SIZE_ITEMS = [
  { key: "FREE", value: "FREE" },
  { key: "XS", value: "XS" },
  { key: "S", value: "S" },
  { key: "M", value: "M" },
  { key: "L", value: "L" },
  { key: "XL", value: "XL" },
  { key: "その他", value: "その他" },
] as const

export const MATERIAL_ITEMS = [
  { key: "Tシャツ（長袖）・長ズボン", value: "short-t-shirt" },
  { key: "Tシャツ（半袖）・半ズボン", value: "long-t-shirt" },
  { key: "スウェット", value: "sweat" },
  { key: "前開き", value: "front-open" },
] as const

export const SEX_VALUES = SEX_ITEMS.map((item) => item.value)
export const FABRIC_VALUES = FABRIC_ITEMS.map((item) => item.value)
export const SEASON_VALUES = SEASON_ITEMS.map((item) => item.value)
export const WEAR_CATEGORY_VALUES = WEAR_CATEGORY_ITEMS.map((item) => item.value)
export const SIZE_VALUES = SIZE_ITEMS.map((item) => item.value)

export type SexValue = (typeof SEX_ITEMS)[number]["value"]
export type SizeValue = (typeof SIZE_ITEMS)[number]["value"]
export type WearCategoryValue = (typeof WEAR_CATEGORY_ITEMS)[number]["value"]
export type SeasonValue = (typeof SEASON_ITEMS)[number]["value"]
export type FabricValue = (typeof FABRIC_ITEMS)[number]["value"]

/**
 * 商品検索のソート順
 */
export const ProductAllSearchInput_Sort = {
  HighPrice: "high_price",
  LowPrice: "low_price",
  Popularity: "popularity",
  Recommendation: "recommendation",
} as const
/**
 * 商品検索のソート順
 */
export type ProductAllSearchInput_Sort = (typeof ProductAllSearchInput_Sort)[keyof typeof ProductAllSearchInput_Sort]
/**
 * 画面用のソート順
 */
export const ProductAllSearchInput_SortItems = {
  [ProductAllSearchInput_Sort.Recommendation]: "おすすめ順",
  [ProductAllSearchInput_Sort.Popularity]: "人気順",
  [ProductAllSearchInput_Sort.LowPrice]: "価格が安い順",
  [ProductAllSearchInput_Sort.HighPrice]: "価格が高い順",
} as const

export const SELECT_GIFT_INIT_DATA = {
  sku_id: "",
  product_id: "",
  product_name: "",
  size: "",
  price: 0,
  sku_name: "",
  sku_code: "",
  img: "",
  message: "",
  message_sku_code: "",
  message_img: "",
  message_price: 0,
  message_sku_id: "",
  card: "",
  card_sku_code: "",
  card_img: "",
  card_price: 0,
  card_sku_id: "",
} as const
/**
 * 返品交換期間の設定値
 */
export const IS_RETURN_OR_EXCHANGE_PERIOD = {
  possible: 14,
  impossible: 0,
  trial: 14,
  trial30days: 30,
  trial120days: 120,
} as const

/**
 * 在庫状況ページに表示する商品
 */
export type CategorySlug = "recovery-wear" | "bedding" | "comfort-tech-wear" | "sandal"
export const PRODUCT_IDS_IN_STOCK_INFO: {
  category_title: string
  category_slug: CategorySlug
  productIds: string[]
}[] = [
  {
    category_title: "リカバリーウェア BAKUNE",
    category_slug: "recovery-wear",
    productIds: [
      // "67ca6adcce9a365145b38d0c", // BAKUNE Dry Men's 上下セット（半袖Tシャツ・ショートパンツ）
      "67be9311f7605c28867c7f5d", // BAKUNE Dry Men's 上下セット（長袖Tシャツ・ロングパンツ）
      // "67ca7b8ace9a365145b4d9a7", // BAKUNE Dry Women's 上下セット（半袖Tシャツ・ショートパンツ）
      "67be95bff7605c28867d04bf", // BAKUNE Dry Women's 上下セット（長袖Tシャツ・ロングパンツ）
      // "67ca81df374cd6ea01be9ccb", // BAKUNE Dry Women's チュニック
      // "67caacf0e7a68f2ca56cac5c", // BAKUNE 3D Dry Men's 上下セット（長袖Tシャツ・ロングパンツ）
      // "67cab019e7a68f2ca56d09ce", // BAKUNE 3D Dry Men's 上下セット（半袖Tシャツ・ショートパンツ）
      // "67cab239e7a68f2ca56d532b", // BAKUNE 3D Dry Women's 上下セット（長袖Tシャツ・ロングパンツ）
      // "67cab42be7a68f2ca56d98be", // BAKUNE 3D Dry Women's 上下セット（半袖Tシャツ・ショートパンツ）
      // "67caba3fe7a68f2ca56ded57", // BAKUNE Mesh 上下セット（半袖Tシャツ・ショートパンツ）
      "67be990af7605c28867d89bc", // BAKUNE Mesh 上下セット（長袖Tシャツ・ロングパンツ）
      // "67cac0b5e7a68f2ca56e32d5", // BAKUNE Pajamas Dry 上下セット（半袖シャツ・ショートパンツ）
      // "67cac38be7a68f2ca56e9fd7", // BAKUNE Pajamas Dry 上下セット（長袖シャツ・ロングパンツ）
      // "67cac76de7a68f2ca56ef736", // BAKUNE Pile 上下セット（半袖Tシャツ・ショートパンツ）
      // "67cacb74e7a68f2ca56f4697", // BAKUNE Pile 上下セット（長袖Tシャツ・ロングパンツ）
      // "67cad474e7a68f2ca56ff684", // BAKUNE Waffle Men's 上下セット（長袖シャツ・ロングパンツ）
      // "67cacfaae7a68f2ca56fa0e5", // BAKUNE Waffle Men's 上下セット（半袖シャツ・ショートパンツ）
      // "67cadcc0c386b93591264ac6", // BAKUNE Waffle Women's 上下セット（長袖シャツ・ロングパンツ）
      // "67cad93ee7a68f2ca57054cb", // BAKUNE Waffle Women's 上下セット（半袖シャツ・ショートパンツ）
      // "67be904ff7605c28867c22ee", // BAKUNE Blanket
      // "67be9211f7605c28867c638d", // BAKUNE HARAMAKI
    ],
  },
  {
    category_title: "Comforter",
    category_slug: "bedding",
    productIds: [
      "67bee76046cc55e963ffcdd1", // BAKUNE Comforter Cool Organic Cotton 夏用肌掛け布団
      "67bed5ea46cc55e963fefea2", // BAKUNE Comforter Cool 夏用肌掛け布団
      "66960f7f3f513e5f6128ae8b", // BAKUNE Comforter All Seasons 通年用掛け布団
      "67bee8b646cc55e963ffea1f", // BAKUNE Bed Pad Cool Organic Cotton 夏用敷きパッド
    ],
  },
  {
    category_title: "MIGARU",
    category_slug: "comfort-tech-wear",
    productIds: [
      // "67ca4ab0ce9a365145aef151", // MIGARU Dry Short Sleeve Shirt Open Collar 上下セット（半袖シャツ・ショートパンツ）
      // "67ca481ece9a365145ae6dbb", // MIGARU Dry Long Pants ロングパンツ
      // "67ca48b1ce9a365145ae9250", // MIGARU Dry Short Pants ショートパンツ
      // "67ca4b5fce9a365145af0fd7", // MIGARU Dry Women’s Tunic チュニック
      // "67ca55d2ce9a365145b02a9d", // MIGARU Light Knit Women's Short Sleeve Tunic チュニック
      // "67ca5697ce9a365145b04c99", // MIGARU Light Knit Women's Short Sleeve T-Shirt 半袖Tシャツ
      // "67ca5795ce9a365145b08fb6", // MIGARU Light Knit Women's Long Pants ロングパンツ
      // "67ca660dce9a365145b2be95", // MIGARU Light Knit Short Sleeve Polo Shirt 半袖ポロシャツ
      // "67ca68b9ce9a365145b341f6", // MIGARU Light Knit Short Sleeve T-Shirt 半袖Tシャツ
      // "67ca5ebdce9a365145b1a74c", // MIGARU Light Knit Short Pants ショートパンツ
      // "67ca6547ce9a365145b2a513", // MIGARU Light Knit Long Pants ロングパンツ
      // "67ca6b49ce9a365145b39f9f", // MIGARU Light Knit Short Sleeve Tailored Polo Shirt 半袖ポロシャツ
      "67bd3606cbab11c78b4e7897", // MIGARU Tech Sweat Pullover Hoodie プルオーバーフーディー
      "67bd4ed7cbab11c78b4f90b3", // MIGARU Tech Sweat Long Pants Tapered テーパードパンツ
    ],
  },
  {
    category_title: "Sandal",
    category_slug: "sandal",
    productIds: [
      // "67ca41efce9a365145ada90b", // Recovery Sandal Flip flop
      "67a3241473eead61841a241e", // Recovery Sandal Slide
      // "67ca40d3ce9a365145ad7b18", // Recovery Sandal Strap
    ],
  },
] as const
